import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, MenuItem, Paper, Select, Switch, Typography } from "@mui/material";
import ReactTimeAgo from "react-time-ago";
import { useState } from "react";
import SwitchController from "./data/SwitchController";
import SensorController from "./data/SensorController";
import RefreshIcon from '@mui/icons-material/Refresh';



function ByPointDashboard({ devicesAndIosArray, deviceStats, reload }) {
    const [selectedDevice, setSelectedDevice] = useState(null)
    const [typeFilter, setTypeFilter] = useState("Stracture")

    const isJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const getIoObj = (id, isSensor) => {
        console.log(id, 'deviceStats')
        if (deviceStats === null)
            return '';
        if (isSensor)
            return deviceStats.map(d => d.sensors.find(s => s.id === id)).find(s => s !== undefined)
        else
            return deviceStats.map(d => d.switches.find(s => s.id === id)).find(s => s !== undefined)
    }


    return (
        <Paper style={{ width: '98%', border: '1px solid #DDD', padding: 5, borderRadius: 10 }}>
            <Grid container direction="column" style={{ justifyContent: "space-between", paddingLeft: 10, paddingRight: 10, textAlign: 'center' }}>
                <h3>
                    Manual management by point
                    <IconButton onClick={() => reload()}>
                        <RefreshIcon />
                    </IconButton>
                </h3>

                <Select onChange={(e) => setTypeFilter(e.target.value)} value={typeFilter} >
                    <MenuItem value={null} selected={typeFilter === null}>All</MenuItem>
                    <MenuItem value="Stracture" selected={typeFilter === "Stracture"}>Stracture</MenuItem>
                </Select>
                <div style={{ padding: 10 }}>
                    {devicesAndIosArray !== null && devicesAndIosArray.filter(p => typeFilter !== null ? p.point_type === typeFilter : true).map(point => <Accordion expanded={selectedDevice === point.point_name}>
                        <AccordionSummary
                            aria-controls="panel1-content"
                            id="panel1-header"
                            onClick={() => point.point_name === selectedDevice ? setSelectedDevice(null) : setSelectedDevice(point.point_name)}
                        >
                            <Grid container direction="row" style={{ justifyContent: "space-between", paddingRight: 10 }}>
                                <Grid item >
                                    <Typography variant="body2">{point.point_name !== null ? point.point_name : "No name provided"}
                                        <small>
                                            {point.point_type !== null ? `(${point.point_type})` : ``}
                                        </small>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <div style={{ borderRadius: '20px', width: '20px', height: '20px', marginLeft: 5, marginRight: 5, backgroundColor: point.point_color }} />
                                </Grid>

                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3}>
                                {isJsonString(point.widget_settingsJson) && Object.values(JSON.parse(point.widget_settingsJson)).filter(w => w !== null && w.widget === 'numericValue').map((w, i) => {
                                    if (w !== null) {
                                        if (w.widget === 'numericValue') {
                                            const sensorObject = getIoObj(w.sensor, true)
                                            return <Grid item xs={6} md={3}>
                                                <SensorController reload={reload} color={w.color} id={w.sensorId} name={w.title} value={sensorObject.currentValueString} updateTime={sensorObject.updateDate} />
                                            </Grid>
                                        }
                                    }
                                })}
                            </Grid>
                            {isJsonString(point.widget_settingsJson) && Object.values(JSON.parse(point.widget_settingsJson)).filter(w => w !== null && w.widget === 'switchValue').map((w, i) => {
                                if (w !== null) {
                                    if (w.widget === 'switchValue') {
                                        const switchObject = getIoObj(w.switchId, false)
                                        return <SwitchController reload={reload} color={w.color} id={w.switchId} name={w.title} value={switchObject.currentValue} updateTime={switchObject.updateDate} />
                                    }
                                }
                            })}
                        </AccordionDetails>
                    </Accordion>)}

                </div>
            </Grid>
        </Paper>
    );
}

export default ByPointDashboard;
