import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ByDeviceDashboard from './ByDeviceDashboard';
import ByPointDashboard from './ByPointDashboard';
import { farmAPI } from '../api/FarmApi';
import { Grid } from '@mui/material';
import WaterGraphReport from './reports/WaterGraphReport';
// import { farmAPI } from '../api/FarmApi';

export default function Dashboard({ value, setValue }) {


  const [deviceStats, setDeviceStats] = useState(null)
  const [pointsStats, setPointsStats] = useState(null)
  const [reportsDays, setReportDays] = useState(30)
  const [waterStatsArray, setWaterStatsArray] = useState([])
  

  useEffect(() => {
    loadByDeviceStats()
    loadWaterStats()
  }, [])
  
  useEffect(() => {
    loadWaterStats()
  }, [reportsDays])

  


  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (newValue === "1") {
      loadByDeviceStats()
    }
    if (newValue === "2") {
      loadByPointsStats()
    }
  };


  const loadByDeviceStats = async () => {
    try {
      const token = localStorage.getItem("token");
      const res = await farmAPI.get('/devices/get', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setDeviceStats(res.data)
    } catch (e) {
      console.log(e);
    }
  }

  const loadByPointsStats = async () => {
    try {
      const token = localStorage.getItem("token");
      const res = await farmAPI.post('/points/getAllWidgetPoints', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setPointsStats(res.data)
    } catch (e) {
      console.log(e);
    }
  }

  const loadWaterStats = async () => {
    try {
      const token = localStorage.getItem("token");
      const res = await farmAPI.get(`/devices/stats/water?period=${reportsDays}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setWaterStatsArray(res.data)
    } catch (e) {
      console.log(e);
    }
  }



  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList style={{ justifyContent: 'center' }} onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="By Device" value="1" />
            <Tab label="By Points" value="2" />
            <Tab label="Report" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Grid container>
            <Grid item xs={12} md={6}>
              <ByDeviceDashboard devicesAndIosArray={deviceStats} reload={loadByDeviceStats} />
            </Grid>
            <Grid item xs={12} md={6}>
              <WaterGraphReport reportDays={reportsDays} setReportDays={setReportDays} type={`device`} reload={loadWaterStats} waterStatsArray={waterStatsArray} deviceStats={deviceStats} />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <Grid container>
            <Grid item xs={12} md={6}>
              <ByPointDashboard devicesAndIosArray={pointsStats} deviceStats={deviceStats} reload={() => {
                loadByDeviceStats()
                loadByPointsStats()
              }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <WaterGraphReport reportDays={reportsDays} setReportDays={setReportDays} type={`point`} deviceStats={deviceStats} reload={loadWaterStats} waterStatsArray={waterStatsArray} pointsStats={pointsStats} />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="3">Report</TabPanel>
      </TabContext>

    </Box>
  );
}