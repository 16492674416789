import * as React from 'react';
import { farmAPI } from '../api/FarmApi';
import { TextField, Button, Typography, Paper } from '@mui/material';

export default function Login(props) {

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState(null);

    const login = async () => {
        try {
            setErrorMessage(null)
            const token = await farmAPI.post('/auth/login', { email, password });
            props.setToken(token.data.token);
            console.log(token.data.token, 'token');
        } catch (e) {
            setErrorMessage('Login failed');
        }
    }

    return <div style={{ textAlign: '-webkit-center', justifyContent: 'center' }}>
        <Paper style={{ textAlign: 'center', margin: 10, padding: 30, height: '50%', width: '80%', display: 'flex', justifyContent: 'center', marginTop: '10%', justifySelf: 'center' }}>
            <div style={{ width: '80%', alignSelf: 'center', justifySelf: 'center', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                {/* <IconButton
            icon="close"
            size={35}
            style={{ alignSelf: 'flex-start', top: '3%' }}
            onPress={() => props.close()}
        /> */}
                <h2 >Login</h2>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <TextField
                        label="Email"
                        value={email}
                        onChange={text => setEmail(text.target.value)}
                        style={{marginBottom: 20}}
                    />

                    <TextField
                        type='password'
                        label="Password"
                        value={password}
                        onChange={text => setPassword(text.target.value)}
                    />
                    <div>

                        {errorMessage != null && <Typography style={{ color: 'red' }}>{errorMessage}</Typography>}

                        <Button onClick={() => login()}  variant='outlined'>
                            Login
                        </Button>


                    </div >
                </div >
            </div >
        </Paper>
    </div>
}