import { Grid, Paper, Switch, Typography } from "@mui/material";
import { farmAPI } from "../../api/FarmApi";
import ReactTimeAgo from "react-time-ago";



function SwitchController({ updateTime, name, value, color, id, reload }) {

    const changeSwitchValue = async (switchId, value) => {
        try {
            const token = localStorage.getItem("token");
            const res = await farmAPI.post('/devices/manual', {
                switchId,
                value: value ? "on" : "off"
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            reload()
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <Paper style={{ width: '98%', border: '1px solid #DDD', padding: 5, borderRadius: 10, margin: 1, marginBottom: 7 }}>
            <Grid container direction="row" style={{ justifyContent: "space-between", paddingRight: 10 }}>
                <Grid item >
                    <Switch checked={value} onChange={() => changeSwitchValue(id, !value)} />
                {/* </Grid>
                <Grid item style={{ alignContent: 'center', display: 'flex' }}> */}
                    {name}
                </Grid>
                <Grid item style={{ alignText: 'right', alignContent: 'center' }}>
                    <Typography style={{ color: '#999' }}>
                        {color !== undefined && <div style={{ borderRadius: '20px', width: '20px', height: '20px', margin: 10, backgroundColor: color }} />}
                        {updateTime !== undefined && <ReactTimeAgo date={updateTime} timeStyle="twitter" />}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default SwitchController;
