import { Grid, Paper, Switch, Typography } from "@mui/material";
import { farmAPI } from "../../api/FarmApi";
import ReactTimeAgo from "react-time-ago";



function SensorController({ updateTime, name, value, color, id, reload }) {


    return (
        <Paper style={{ width: '95%', border: '1px solid #DDD', borderRadius: 10, marginBottom: 7, padding: 10 }}>
            <Grid container direction="row">
                <Grid item >
                    {name}:
                </Grid>
                <Grid item >
                    {value}
                </Grid>
                <Grid item style={{ alignText: 'right', alignContent: 'center' }}>
                    <Typography style={{ color: '#999' }}>
                        {color !== undefined && <div style={{ borderRadius: '20px', width: '20px', height: '20px', margin: 10, backgroundColor: color }} />}
                        {updateTime !== undefined && <ReactTimeAgo date={updateTime} timeStyle="twitter" />}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default SensorController;
