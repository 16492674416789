import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, Paper, Switch, Typography } from "@mui/material";
import ReactTimeAgo from "react-time-ago";
import { useState } from "react";
import SwitchController from "./data/SwitchController";
import moment from "moment/moment";
import SensorController from "./data/SensorController";
import RefreshIcon from '@mui/icons-material/Refresh';


function ByDeviceDashboard({ devicesAndIosArray, reload }) {
    const [selectedDevice, setSelectedDevice] = useState(null)

    console.log(devicesAndIosArray, 'devicesAndIosArray')
    return (
        <Paper style={{ width: '98%', border: '1px solid #DDD', padding: 5, borderRadius: 10 }}>
            <Grid container direction="column" style={{ justifyContent: "space-between", paddingLeft: 10, paddingRight: 10, textAlign: 'center' }}>
                <h3>
                    Manual management by device

                    <IconButton onClick={() => reload()}>
                        <RefreshIcon />
                    </IconButton>
                </h3>
                <div style={{ padding: 10 }}>
                    {devicesAndIosArray !== null && devicesAndIosArray.map(device => <Accordion key={device.id} expanded={selectedDevice === device.name}>
                        <AccordionSummary
                            aria-controls="panel1-content"
                            id="panel1-header"
                            onClick={() => device.name === selectedDevice ? setSelectedDevice(null) : setSelectedDevice(device.name)}
                        >

                            <Grid container direction="row" style={{ justifyContent: "space-between", paddingRight: 10 }}>
                                <Grid item >
                                    <Typography variant="body2">{device.name} <small>({device.model} v{device.version})</small></Typography>
                                </Grid>

                                <Grid item style={{ alignText: 'right', alignContent: 'center' }}>
                                    <small><ReactTimeAgo date={new Date(device.updateDate)} timeStyle="twitter" /></small>
                                </Grid>
                            </Grid>



                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3}>
                                {device.sensors.map((s, i) => <Grid item xs={6} md={3} key={i}><SensorController reload={reload} key={i} {...s} value={s.currentValueString} updateTime={s.updateDate} /></Grid>)}
                            </Grid>
                            {device.switches.map((s, i) => <SwitchController reload={reload} key={i} {...s} value={s.currentValue} updateTime={s.updateDate} />)}
                        </AccordionDetails>
                    </Accordion>)}

                </div>
            </Grid>
        </Paper>
    );
}

export default ByDeviceDashboard;
