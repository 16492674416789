import logo from './logo.svg';
import './App.css';
import SwitchController from './components/data/SwitchController';
import MenuIcon from '@mui/icons-material/Menu';
import { useEffect, useState } from 'react';
import { Button, Drawer, IconButton, Typography } from '@mui/material';
import Dashboard from './components/Dashboard';
import { farmAPI } from './api/FarmApi';
import Login from './components/Login';
import en from 'javascript-time-ago/locale/en'
import TimeAgo from 'javascript-time-ago'
TimeAgo.addDefaultLocale(en)


function App() {

  const [openMenu, setOpenMenu] = useState(false)
  const [token, setToken] = useState(null)
  
  const [value, setValue] = useState('1');


  useEffect(() => {
    if (token !== null)
      localStorage.setItem("token", token);
  }, [token])

  useEffect(() => {
    const storageToken = localStorage.getItem("token");
    console.log(storageToken, 'storageToken')
    if (storageToken!== null && storageToken.length !== 0)
      setToken(storageToken)
  }, [])


  const logout = () => {
    localStorage.setItem("token", '');
    window.location.href = window.location.href
  }


  if (token === null)
    return <>
      <Login setToken={setToken} />
    </>

  return (
    <div className="App">
      <header>
        <IconButton style={{ float: 'left' }} onClick={() => setOpenMenu(!openMenu)}>
          <MenuIcon />
        </IconButton>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <img src={logo} alt="Logo" style={{ justifySelf: "center" }} />
        </div>
      </header>
      <Drawer open={openMenu} onClose={() => setOpenMenu(!openMenu)}>
        <div style={{ width: '200px', padding: 20 }}>
          <Typography style={{ textAlign: 'center' }}>
            Menu
          </Typography>
          <Button onClick={() => logout()}>Logout</Button>
        </div>
      </Drawer>



      <Dashboard
        value={value}
        setValue={setValue}
      />



    </div>
  );
}

export default App;
