import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, Link, MenuItem, NativeSelect, Paper, Select, Switch, Typography } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import ReactTimeAgo from "react-time-ago";
import { useState } from "react";
import RefreshIcon from '@mui/icons-material/Refresh';
import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';




function WaterGraphReport({ type, waterStatsArray, deviceStats, pointsStats, reportDays, setReportDays, reload }) {


    const isJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const deviceData = waterStatsArray.reduce((acc, current) => {
        const deviceId = current.deviceId;
        const date = current.collectionDate;
        const sumNumericValue = parseFloat(current.sumNumericValue);

        const deviceName = deviceStats.find(d => d.id === deviceId).name

        if (!acc[deviceId]) {
            acc[deviceId] = {
                deviceId,
                name: deviceName,
                quantity: sumNumericValue,
                date,
            };
        } else {
            acc[deviceId].quantity += sumNumericValue;
        }

        return acc;
    }, {});

    let pointToSwitchArray = [];
    let pointNameColorArray = [];
    if (pointsStats !== undefined && pointsStats !== null) {
        pointsStats.map((point, i) => {
            pointNameColorArray[point.point_name] = point.point_color
            isJsonString(point.widget_settingsJson) && Object.values(JSON.parse(point.widget_settingsJson))
                .filter(w => w !== null && w.widget === 'switchValue')
                .map((w, i) => pointToSwitchArray[point.point_name] !== undefined ? pointToSwitchArray[point.point_name].push(w.switchId) : pointToSwitchArray[point.point_name] = [w.switchId])
        })
    }

    const pointsSwitchData = waterStatsArray.reduce((acc, current) => {
        // if (pointToSwitchArray === undefined || pointToSwitchArray === null)
        //     return
        const switchId = current.switchId;
        const date = current.collectionDate;
        const sumNumericValue = parseFloat(current.sumNumericValue);

        const loadedPoint = Object.keys(pointToSwitchArray).find(pointName => pointToSwitchArray[pointName].includes(switchId) ? pointName : undefined)

        if (!acc[loadedPoint]) {
            acc[loadedPoint] = {
                switchName: current.switchName,
                name: loadedPoint,
                quantity: sumNumericValue,
                color: pointNameColorArray[loadedPoint],
                date,
            };
        } else {
            acc[loadedPoint].quantity += sumNumericValue;
        }

        return acc;
    }, {});

    const data = Object.values(deviceData)
    const pointsData = Object.values(pointsSwitchData)
    const COLORS = type === 'device' ? ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'] : pointsData.map(p => p.color);

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (percent > 0.1 ? <>
            <text color='#000' x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} >
                {`${(percent * 100).toFixed(0)}%`}

            </text>
            <text x={x - 40} y={y + 20} fontSize={10}>{name}</text>
        </> :
            <></>
        );
    };

    const width = window.innerWidth > 700 ? window.innerWidth / 2.9 : window.innerWidth-120
    const height = 150

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`${payload[0].name} : ${payload[0].value / 100} Liters`}</p>
                    {/* <p className="intro">{getIntroOfPage(label)}</p> */}
                    {/* <p className="desc">Anything you want can be displayed here.</p> */}
                </div>
            );
        }

        return null;
    };

    return (
        <Paper style={{ width: '98%', border: '1px solid #DDD', padding: 5, borderRadius: 10 }}>
            <Grid container direction="column" style={{ justifyContent: "space-between", paddingLeft: 10, paddingRight: 10, textAlign: 'center' }}>
                <h3>
                    Water consumption by {type}
                    <IconButton onClick={() => reload()}>
                        <RefreshIcon />
                    </IconButton>
                </h3>
                <div style={{ padding: 10, alignSelf: 'center' }}>
                    <div style={{display: 'flex', justifyContent:"space-evenly", width: '80%'}} >
                    <InputLabel id="select-label">Number of days:</InputLabel>
                    {/* {[1,7,30,60,90].map(d=> reportDays === d ? <Typography>{d}</Typography>  : <Link onClick={() => setReportDays(d)}>{d===1 ? 'Today' : d}</Link> )} */}
                    
                    <Select labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) => setReportDays(e.target.value)} 
                        value={reportDays} 
                        defaultValue={reportDays} 
                        defaultChecked={reportDays} 
                        size="small"
                        // style={{ minWidth: 40 }}
                        >
                        <MenuItem value={1} selected={reportDays === 1}>Today</MenuItem>
                        <MenuItem value={7} selected={reportDays === 7}>7</MenuItem>
                        <MenuItem value={30} selected={reportDays === 30}>30</MenuItem>
                        <MenuItem value={60} selected={reportDays === 60}>60</MenuItem>
                        <MenuItem value={90} selected={reportDays === 90}>90</MenuItem>
                    </Select>
                    </div>
                    <PieChart width={width} height={height}>
                        <Tooltip contentStyle={{ color: '#000' }} content={<CustomTooltip />} />
                        <Pie
                            data={type !== "device" ? pointsData : data}
                            // cx={120}
                            cy={height - 50}
                            innerRadius={20}
                            outerRadius={height / 1.5}
                            startAngle={180}
                            endAngle={0}
                            fill="#8884d8"
                            paddingAngle={1}
                            dataKey="quantity"
                            label={renderCustomizedLabel}
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                    </PieChart>
                </div>
            </Grid>
        </Paper >
    );
}

export default WaterGraphReport;
