import axios from 'axios';
import axiosRetry from 'axios-retry';

export const farmAPI = axios.create({
  baseURL: 'http://iot.regenatech.com:5001/',
  // baseURL: 'http://104.248.253.92:5000/',
  
  // baseURL: 'http://192.168.1.171:5001/',
  // timeout: 2000,
  retries: 5,
});


farmAPI.interceptors.request.use(
  async req => {
    const token = localStorage.getItem("token");
      req.headers.Authorization = `Bearer ${token || ''}`;
    return req;
  },
  error => {
    return Promise.reject(error);
  }
);

// farmAPI.interceptors.response.use(
//   res => {
//     return res;
//   },
//   function (error) {
//     if (401 === error?.response?.status) {
//       // sessionStorage.clear();
//       // window.location.replace('/login');
//     } else {
//       return Promise.reject(error);
//     }
//   }
// );

axiosRetry(farmAPI, {
  retries: 10,
  onRetry: (cnt, err) => {
    console.log('retying ('+cnt+')... Error: ', err)
  },
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  }
});
